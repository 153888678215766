import * as React from "react";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import ReCaptchaComponent from "../components/Captcha/ReCaptchaComponent";

// Bootstrap styles
import "bootstrap/dist/css/bootstrap.min.css";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

// Custom components
import CustomNav from "../components/Nav/CustomNav";
import Footer from "../components/Footer/Footer";

// Custom styles
import "../assets/scss/provider.styles.scss";

// Media assets
import MainMap from "../components/Maps/MainMap";
import ProductsPill from "../components/Products/ProductsPill";
import ProviderServices from "../components/ProviderServices/ProviderServices";
import Swal from "sweetalert2";

import {useI18next, useTranslation} from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";
import axios from "axios";
import {Controller, useForm} from "react-hook-form";

let providersMarkers = [];
providersMarkers.push({
  latitude: 19.4326018,
  longitude: -99.1332049,
  title: "Location",
  draggable: true,
  clickable: false
});

const ProviderPage = () => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [token, setToken] = useState("");
  const onGetTokenRecaptcha = (e) => {
    setToken(e);
  };
  const onExpiredTokenRecaptcha = (e) => {
    setToken("");
  };

  function sentForm(data) {
    if (
      data == null ||
      data.location == null ||
      (data.location.latitude === 19.4326018 &&
        data.location.longitude === -99.1332049)
    ) {
      Swal.fire({
        icon: "error",
        title: t("Missing Information"),
        text: t("Please select the location of your facility on the map"),
      });

      return;
    }

    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please validate recaptcha",
      });

      return;
    }

    const dataForm = {
      ...data,
      latitude: data.location.latitude,
      longitude: data.location.longitude,
    };
    delete dataForm.location;

    console.log(dataForm);

    let optionAxios = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        process.env.GATSBY_REST_API_URL + "/providers",
        { provider: dataForm, token },
        optionAxios
      )
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: `${t("Thanks")} ${dataForm.name}`,
          text: t(
            "We have received your review and we will redirect you to the provider's profile so that you can continue surfing"
          ),
        });

        setTimeout(function () {
          window.location.href = process.env.GATSBY_SITE_URL;
        }, 5000);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${t("There was an error saving the information")} ${error.message
            }`,
        });
      });
  }

  const [dataProducts, setDataProducts] = useState([]);
  const [dataServices, setDataServices] = useState([]);

  useEffect(() => {
    const providerServices = [];
    const productPills = [];
    const dataProviderServices = axios.get(
      process.env.GATSBY_REST_API_URL + `/services`
    );
    dataProviderServices
      .then((response) => {
        let services = response.data;
        if (services != null && services.length > 0) {
          services?.forEach(function (service) {
            providerServices.push({ id: service.id, name: service.name });
          });
        }
      })
      .catch((errors) => {
        console.err(errors);
      });

    const dataProductPills = axios.get(
      process.env.GATSBY_REST_API_URL + `/products`
    );
    dataProductPills
      .then((response) => {
        let products = response.data;
        if (products != null && products.length > 0) {
          products?.forEach(function (product) {
            productPills.push({ id: product.id, name: product.name });
          });
        }
      })
      .catch((errors) => {
        console.err(errors);
      });

    axios
      .all([dataProviderServices, dataProductPills])
      .then(
        axios.spread((...responses) => {
          const dataProviderServices = responses[0].data;
          const dataProductPills = responses[1].data;

          setDataProducts(dataProviderServices);
          setDataServices(dataProductPills);
        })
      )
      .catch((errors) => {
        console.err(errors);
      });
  }, []);

  return (
    <>
      <Helmet htmlAttributes={{ lang: language }}>
        <meta charSet="utf-8" />
        {/* <!-- HTML Meta Tags --> */}
        <title>
          {t("Find My Method: Create Contraceptive Provider Profile")}
        </title>
        <meta
          name="description"
          content={t(
            "Do you want to register as a contraceptive or birth control provider? Find My Method provides you the platform to add your products or services to let users know about you."
          )}
        />
        <meta
          name="keywords"
          content="Contraceptive Services - 150 global search volume, contraceptive method"
        />
        {<link rel="canonical" href={process.env.GATSBY_SITE_URL}/>}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://findmymethod.org/wp-content/uploads/2019/05//apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://findmymethod.org/wp-content/uploads/2019/05//favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://findmymethod.org/wp-content/uploads/2019/05//favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="https://findmymethod.org/wp-content/uploads/2019/05//site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="https://findmymethod.org/wp-content/uploads/2019/05//safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#5bbad5" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <CustomNav theme="light" />
      <main style={{ position: "relative" }}>
        <title>
          {t("Find My Method: Create Contraceptive Provider Profile")}
        </title>
        <div className="provider">
          <section className="hero-jumbotron--register">
            <Container>
              <h1>{t("Are you a provider?")}</h1>
              <p>
                {t(
                  "Find My Method wants to close the gap between consumers and providers, register you facility and let our user know your services."
                )}
              </p>
              <p>
                {t(
                  "*This is a beta version. Test it out and help us make it bigger and better. Send your feedback to info@findmymethod.org"
                )}
              </p>
            </Container>
          </section>
          <section>
            <Form
              onSubmit={handleSubmit((data) => {
                sentForm(data);
              })}
            >
              <section className="form__wrapper">
                <Container>
                  <h2>{t("Fill out the following form")}</h2>
                  <p>
                    {t(
                      "This review will help other to access quality service."
                    )}
                  </p>
                  <Row className="form__container">
                    <Col xs={12} lg={6}>
                      <h5>{t("Provider details")}</h5>
                      <Form>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            xs={12}
                            lg={6}
                            className="mb-3 mt-lg-0"
                          >
                            <Form.Label
                              style={{ color: errors.name ? "red" : "" }}
                            >
                              {t("Name of your facility")}*
                            </Form.Label>
                            <Form.Control
                              id="name"
                              {...register("name", { required: true })}
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group as={Col} xs={12} lg={6}>
                            <Controller
                              control={control}
                              name="type_facility"
                              rules={{ required: true }}
                              render={({ field: { onChange } }) => (
                                <>
                                  <Form.Label
                                    style={{
                                      color: errors.type_facility ? "red" : "",
                                    }}
                                  >
                                    {t("Type of facility")}*
                                  </Form.Label>
                                  <Form.Select
                                    id="type_facility"
                                    defaultValue="Choose..."
                                    onChange={onChange}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <option value={0}>{t("Choose...")}</option>
                                    <option value={1}>
                                      {t("Private hospital")}
                                    </option>
                                    <option value={2}>
                                      {t("Private clinic")}
                                    </option>
                                    <option value={3}>
                                      {t("Doctor's office")}
                                    </option>
                                    <option value={4}>
                                      {t("Public hospital")}
                                    </option>
                                    <option value={5}>
                                      {t("Public clinic")}
                                    </option>
                                    <option value={6}>{t("Pharmacy")}</option>
                                    <option value={7}>
                                      {t("Supermarket")}
                                    </option>
                                    <option value={8}>
                                      {t("Convenience store")}
                                    </option>
                                    <option value={9}>{t("Sex shop")}</option>
                                  </Form.Select>
                                </>
                              )}
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            xs={12}
                            lg={6}
                            className="mb-3 mt-lg-0"
                          >
                            <Controller
                              control={control}
                              name="country"
                              rules={{ required: true }}
                              render={({ field: { onChange } }) => (
                                <>
                                  <Form.Label
                                    style={{
                                      color: errors.country ? "red" : "",
                                    }}
                                  >
                                    {t("Country")}*
                                  </Form.Label>
                                  <Form.Select
                                    id="country"
                                    defaultValue="Choose..."
                                    onChange={onChange}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <option>{t("Choose...")}</option>
                                    <option>{t("Mexico")}</option>
                                  </Form.Select>
                                </>
                              )}
                            />
                          </Form.Group>
                          <Form.Group as={Col} xs={12} lg={6}>
                            <Controller
                              control={control}
                              name="region"
                              rules={{ required: true }}
                              render={({ field: { onChange } }) => (
                                <>
                                  <Form.Label
                                    style={{
                                      color: errors.region ? "red" : "",
                                    }}
                                  >
                                    {t("Region")}*
                                  </Form.Label>
                                  <Form.Select
                                    id="region"
                                    defaultValue="Choose..."
                                    onChange={onChange}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <option>{t("Choose...")}</option>
                                    <option>{t("Mexico City")}</option>
                                  </Form.Select>
                                </>
                              )}
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} xs={12} lg={6}>
                            <Form.Label
                              style={{ color: errors.zip_code ? "red" : "" }}
                            >
                              {t("Zip Code")}*
                            </Form.Label>
                            <Form.Control
                              id="zip_code"
                              {...register("zip_code", { required: true })}
                              type="text"
                            />
                          </Form.Group>
                        </Row>

                        <Form.Group as={Col} className="mb-3">
                          <Form.Label
                            style={{ color: errors.address ? "red" : "" }}
                          >
                            {t("Address")}*
                          </Form.Label>
                          <Form.Control
                            id="address"
                            {...register("address", { required: true })}
                            type="text"
                          />
                        </Form.Group>

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            xs={12}
                            lg={6}
                            className="mb-3 mt-lg-0"
                          >
                            <Form.Label
                              style={{
                                color: errors.phone_contact ? "red" : "",
                              }}
                            >
                              {t("Phone Number")}*
                            </Form.Label>
                            <Form.Control
                              type="tel"
                              id="phone_contact"
                              {...register("phone_contact", {
                                required: true,
                                // valueAsNumber: true,
                                validate: (input) => {
                                  // Ref: https://stackoverflow.com/questions/8634139/phone-validation-regex
                                  return /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(input);
                                },
                              })}
                            />
                          </Form.Group>

                          <Form.Group as={Col} xs={12} lg={6}>
                            <Form.Label style={{
                              color: errors.whatsapp_contact ? "red" : "",
                            }}>{t("WhatsApp Number")}</Form.Label>
                            <Form.Control
                              type="text"
                              rules={{ required: false }}
                              id="whatsapp_contact"
                              {...register("whatsapp_contact", {
                                // valueAsNumber: true,
                                validate: (input) => {
                                  return /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(input);
                                },
                              })}
                            />
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            xs={12}
                            lg={6}
                            className="mb-3 mt-lg-0"
                          >
                            <Form.Label>{t("Facebook")}</Form.Label>
                            <Form.Control
                              type="text"
                              id="facebook_link"
                              {...register("facebook_link")}
                            />
                          </Form.Group>

                          <Form.Group as={Col} xs={12} lg={6}>
                            <Form.Label>{t("Website")}</Form.Label>
                            <Form.Control
                              type="text"
                              id="website"
                              {...register("website")}
                            />
                          </Form.Group>
                        </Row>
                      </Form>
                    </Col>
                    <Col xs={12} lg={6} className="mt-4 mt-lg-0">
                      {t(
                        "* Use this map to indicate the exact place where your facility is located"
                      )}
                      <div id="current_location" style={{ display: "none" }}>
                        {" "}
                      </div>

                      <Controller
                        control={control}
                        name="location"
                        render={({ field: { onChange } }) => (
                          <MainMap
                            default_lat={19.4326018}
                            default_lng={-99.1332049}
                            zoom={12}
                            staticMarkers={false}
                            markers={providersMarkers}
                            width={"100%"}
                            height={"600px"}
                            position={"relative"}
                            defaultStyle={true}
                            geometryColor="#aff5cd"
                            lines="#4aec91"
                            getDataLocation={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              <section className="provider__secondary-info">
                <Container>
                  <Row>
                    <Col
                      xs={12}
                      lg={6}
                      className="provider__secondary-info__products"
                    >
                      <h5>{t("Available Services")}</h5>
                      <Controller
                        control={control}
                        name="products"
                        render={({ field: { onChange } }) => (
                          <ProductsPill
                            dataProducts={dataProducts}
                            getDataProducts={onChange}
                          />
                        )}
                      />
                    </Col>
                    <Col
                      xs={12}
                      lg={6}
                      className="provider__secondary-info__services"
                    >
                      <h5>{t("Available Products")}</h5>
                      <Controller
                        control={control}
                        name="services"
                        render={({ field: { onChange } }) => (
                          <ProviderServices
                            getDataService={onChange}
                            dataServices={dataServices}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              <section className="provider__submission">
                <Container>
                  <Row>
                    <Col xs={12} lg={6} className="text-center">
                      <Form.Group id="formGridCheckbox">
                        <Form.Check id={"confirmation_form"}>
                          <Form.Check.Label style={{ color: errors.termAndCondition ? "red" : "" }}>
                            {/* TODO Generate a new class for this style */}
                            <Form.Check.Input
                              style={{
                                height: "1em",
                                paddingTop: "0.7rem",
                                paddingBottom: "0.7rem",
                                borderRadius: "50px",
                                backgroundColor: "#0d6efd",
                              }}
                              type={"checkbox"}
                              {...register("termAndCondition", { required: true })}
                            />{" "}
                            {t(
                              "By clicking this box you accept the use of the information to be listed in our directory. For more information about the use of your data, please read our"
                            )}{" "}
                            <a
                              href={t("url_terms_conditions")}
                              target={"_blank"}
                            >
                              {t("Terms and Conditions")}
                            </a>{" "}
                            {t("and the")}{" "}
                            <a href={t("url_privacy_policy")} target={"_blank"}>
                              {t("Privacy Policy")}
                            </a>
                          </Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6} className="text-center">
                      <ReCaptchaComponent
                        id={"captcha-provider"}
                        onGetTokenRecaptcha={onGetTokenRecaptcha}
                        onExpiredTokenRecaptcha={onExpiredTokenRecaptcha}
                      />
                    </Col>
                  </Row>
                  <Button type="submit">{t("Submit Registration")}</Button>
                </Container>
              </section>
            </Form>
          </section>
          {/*Default html*/}
        </div>
        <Footer />
      </main>
    </>
  );
};
export default ProviderPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
