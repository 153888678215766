import React, { useEffect, useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import circularPillImgUrl from "../../images/pill--white.svg";

export default function ProductsPill({ getDataProducts, dataProducts }) {
  const { t } = useTranslation();
  const [productsSelected, setProductsSelected] = useState([]);

  function clicked(id) {
    setProductsSelected((prevState) => [...prevState, id]);

    if (productsSelected.includes(id)) {
      const newArr = productsSelected.filter((item) => item != id);
      setProductsSelected(newArr);
    }
  }

  useEffect(() => {
    getDataProducts(productsSelected);
  }, [productsSelected]);

  return (
    <>
      {dataProducts &&
        dataProducts.map((product) => (
          <div
            key={product.id}
            id={"product_" + product.id}
            style={{
              backgroundColor: productsSelected.includes(product.id)
                ? "#dc3545"
                : "transparent",
              borderColor: "#f78f2d",
              cursor: "pointer",
            }}
            className="form__product-pill"
            onClick={() => {
              clicked(product.id);
            }}
          >
            <img src={circularPillImgUrl} alt="Find my method" />
            <p>{t(product.name)}</p>
          </div>
        ))}
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
