import React, { useState, useEffect } from "react";
// Bootstrap components
import Form from "react-bootstrap/Form";

import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

// Bootstrap styles
import "bootstrap/dist/css/bootstrap.min.css";
// Custom styles
import "../../assets/scss/provider.styles.scss";

export default function ProviderServices({ getDataService, dataServices }) {
  const { t } = useTranslation();
  const [serviceSelected, setServiceSelected] = useState([]);

  function clicked(id) {
    setServiceSelected((prevState) => [...prevState, id]);

    if (serviceSelected.includes(id)) {
      const newArr = serviceSelected.filter((item) => item != id);
      setServiceSelected(newArr);
    }
  }

  useEffect(() => {
    getDataService(serviceSelected);
  }, [serviceSelected]);

  return (
    <ul>
      {dataServices &&
        dataServices.map((service) => (
          <Form.Check
            className={"checkboxesService"}
            type="checkbox"
            label={t(service.name)}
            id={"service_" + service.id}
            key={service.id}
            onClick={() => {
              clicked(service.id);
            }}
          />
        ))}
    </ul>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
